import { CommonState, Fetcher } from '@primeit/components-vue';
import moment from 'moment';

const module = {
  actions: {
    FETCH_OPERATIONAL_PERFORMANCE: async (context, params) => {
      let json;
      
      const formattedDate = `start=${moment(params.date[0], 'YYYY-MM-DD').valueOf()}|end=${moment(params.date[1], 'YYYY-MM-DD').add(1, 'days').valueOf()}`;

      try {
        const response = await Fetcher(`${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_DASHBOARD}/operational-performance?date=${formattedDate}&scale=${params.scale}`);

        if (response.ok) {
          json = await response.json();
        }
      } catch (error) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      context.commit('POPULATE_OPERATIONAL_PERFORMANCE', json);
    },
    FETCH_QUALITY_TRACKER: async (context, params) => {
      let json;
      
      const formattedDate = `start=${moment(params.date[0], 'YYYY-MM-DD').valueOf()}|end=${moment(params.date[1], 'YYYY-MM-DD').add(1, 'days').valueOf()}`;

      try {
        const response = await Fetcher(`${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_DASHBOARD}/quality-tracker?state=${params.state || ''}&city=${params.city || ''}&date=${formattedDate}&scale=${params.scale}`);

        if (response.ok) {
          json = await response.json();
        }
      } catch (error) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      context.commit('POPULATE_QUALITY_TRACKER', json);
    },
  },
  getters: {
    GET_OPERATIONAL_PERFORMANCE: (state) => JSON.parse(JSON.stringify(state.operational_performance)),
    GET_QUALITY_TRACKER: (state) => JSON.parse(JSON.stringify(state.quality_tracker))
  },
  mutations: {
    POPULATE_OPERATIONAL_PERFORMANCE: (state, payload) => {
      if (payload) {
        state.operational_performance = payload;
      }
    },
    POPULATE_QUALITY_TRACKER: (state, payload) => {
      if (payload) {
        state.quality_tracker = payload;
      }
    },
  },
  namespaced: true,
  state: {
    operational_performance: null,
    quality_tracker: null,
  },
};

CommonState.registerModule('dashboard', module);
