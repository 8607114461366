import { CommonRouter } from '@primeit/components-vue';

CommonRouter.registerRoutes([
  {
    children: [
      {
        component: () => import(/* webpackChunkName: "dashboard.quality" */ './pages/quality/main.vue'),
        meta: {
          label: 'dashboard.quality.title',
          secure: true,
        },
        path: '/dashboard/quality',
      },
      {
        component: () => import(/* webpackChunkName: "dashboard.quality" */ './pages/operational/main.vue'),
        meta: {
          label: 'dashboard.operational.title',
          secure: true,
        },
        path: '/dashboard/operational',
      },
    ],
    component: () => import(/* webpackChunkName: "app.common" */ '../common/Common.vue'),
    meta: {
      icon: 'mdi-view-dashboard',
      label: 'dashboard.main',
      order: 0,
      subroutes: [
        {
          icon: 'mdi-face-agent',
          label: 'dashboard.quality.title',
          path: '/dashboard/quality',
          visible: true,
        },
        {
          icon: 'mdi-progress-wrench',
          label: 'dashboard.operational.title',
          path: '/dashboard/operational',
          visible: true,
        },
      ],
      visible: true,
    },
    path: '/dashboard',
  },
]);
