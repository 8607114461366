import './company.js';
import './common.js';
import './dashboard.js';
import './domain.js';
import './patient.js';
import './doctor.js';
import './appointment.js';
import './user.js';
import './settings.js';
import './financial.js';
import './login.js';
import './schedule.js';
import './voucher.js';
import './emergency.js';
import './contract.js';
import './term.js';
import './screening.js';
import './notifications';
import './message';
import './plan';
